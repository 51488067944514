<template>
  <v-btn
    v-if="upgrade"
    class="white--text"
    color="red"
    data-test="upgrade_workspace_category_button"
    elevation="0"
    @click="getUpgradeLink">
    Upgrade
  </v-btn>
</template>
<script>
import {
  mapGetters, mapState,
} from 'vuex';
export default {
  name: 'AppHeaderLayoutUpgradeBtn',
  props: {
    userId: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters('Workspace', ['getActiveWorkspaceOwner', 'isPersonalWorkspace', 'isPremiumWorkspace']),
    ...mapState('Workspace', ['activeWorkspaceId']),
    upgrade() {
      return this.getActiveWorkspaceOwner?.id === this.userId && !this.isPersonalWorkspace && !this.isPremiumWorkspace;
    },
  },
  methods: {
    getUpgradeLink() {
      this.$router.push({
        name: 'workspace-payment',
        params: {
          wId: this.activeWorkspaceId,
        },
      });
    },
  },
};
</script>
